<template>
  <div style="min-height: 400px">
    <ScreenSelect
      @changeTimeRange="selectTime"
      :typeOptions="typeOptions"
      @changeType="changeType"
    />
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      :row-key="record => record.pkId"
      :loading="loading"
      @change="handlePagination"
    />
  </div>
</template>

<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { deviceTypeEnum } from '@/enum/device';
import { sewageInfo } from '@/services/device-manage/device-detail';
import ScreenSelect from '@/views/safety-device/component/screen-select';
import { v4 as uuidv4 } from 'uuid';

@Component({
  components: {
    ScreenSelect,
  },
})
export default class DeviceStatus extends Vue {
  @Prop({ type: String, default: '' }) pageType;
  @Prop({ type: String, default: '' }) deviceId;

  @Watch('deviceId')
  changeDeviceId(newVal, oldVal) {
    if (newVal && newVal !== oldVal) {
      this.deviceId = newVal;
      this.getTableList();
    }
  }
  mounted() {
    this.getTableList();
  }
  formateTime(t) {
    return t ? this.dayjs(t).format('YYYY-MM-DD HH:mm:ss') : '';
  }
  get columns() {
    return [
      {
        align: 'left',
        title: this.$t('safety.reportTime'),
        width: 200,
        dataIndex: 'lastUpdateTime',
        customRender: txt => this.formateTime(txt),
      },
      {
        align: 'left',
        title: this.$t('detail.monitorIndex'),
        width: 100,
        dataIndex: 'indicator',
      },
      {
        align: 'left',
        title: this.$t('safety.status'),
        width: 100,
        dataIndex: 'status',
        customRender: txt => (txt === 1 ? '正常' : '异常'),
      },
      {
        align: 'left',
        title: this.$t('detail.monitorValue'),
        width: 100,
        dataIndex: 'monitorValue',
      },
    ];
  }
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '40'],
    showTotal: total => `${total} ${this.$t('common.total')}`,
  };
  get deviceGroup() {
    return deviceTypeEnum[this.pageType].deviceGroup;
  }
  rangeTime = {
    startTime: '',
    endTime: '',
  };
  selectTime(t) {
    if (t && t.startTime) {
      this.rangeTime.startTime = t.startTime;
      this.rangeTime.endTime = t.endTime;
    } else {
      this.rangeTime = {
        startTime: '',
        endTime: '',
      };
    }
    this.pagination.current = 1;
    this.getTableList();
  }
  loading = false;
  indicatorObj = {
    windSpeed: this.$t('detail.windspeed'),
    temperature: this.$t('safety.temperature'),
    ph: 'PH',
    redoxPotential: this.$t('detail.redoxPotential'),
    residualChlorine: this.$t('detail.residualChlorine'),
    turbidity: this.$t('detail.turbidity'),
    suspendedMatter: this.$t('detail.suspendedSolids'),
    dissolvedOxygen: this.$t('detail.dissolvedOxygen'),
    conductivity: this.$t('detail.conductivity'),
  };
  indicatorUnit = {
    windSpeed: this.$t('unit.meter') + '/' + this.$t('unit.second'),
    temperature: '℃',
    ph: '',
    redoxPotential: 'mv',
    residualChlorine: 'mg/L',
    turbidity: 'mg/L',
    suspendedMatter: 'mg/L',
    dissolvedOxygen: 'mg/L',
    conductivity: 'uS/cm',
  };
  get typeOptions() {
    const result = [];
    for (const key in this.indicatorObj) {
      const obj = {
        label: this.indicatorObj[key],
        value: `${key}-${key}Status`,
      };
      result.push(obj);
    }
    return result;
  }
  selectType = [];
  changeType(v) {
    if (v && v.value !== 'all') {
      this.selectType = v.value ? v.value.split('-') : [];
    } else {
      this.selectType = '';
    }
    this.pagination.current = 1;
    this.getTableList();
  }
  tableData = [];
  async getTableList() {
    if (!this.deviceId) {
      return;
    }
    this.tableData = [];
    const params = {
      pkId: this.deviceId,
      current: this.pagination.current,
      size: this.pagination.pageSize,
    };
    if (this.rangeTime.startTime) {
      params.startTime = this.rangeTime.startTime;
      params.endTime = this.rangeTime.endTime;
    }
    if (this.selectType.length > 0) {
      params.fields = this.selectType;
    }
    try {
      this.loading = true;
      const { records, current, size, total } = await sewageInfo(
        params,
        this.deviceGroup,
      );
      records.forEach(v => {
        const arr = this.splitData(v);
        this.tableData = this.tableData.concat(arr);
      });
      if (this.selectType.length > 0) {
        const s1 = this.selectType[0];
        const s2 = this.selectType[1];
        this.tableData = records.map(v => ({
          pkId: v.pkId,
          lastUpdateTime: v.lastUpdateTime,
          status: v[s2],
          monitorValue: v[s1],
          indicator: this.indicatorObj[s1],
        }));
      }
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.pagination.total = total;
      this.loading = false;
    } catch {
      this.loading = false;
      return false;
    }
  }
  changeMinusOne(val) {
    return val !== -1 ? val : '-';
  }
  splitData(row) {
    const result = [];
    const indicatorText = this.indicatorObj;
    const indicatorUnit = this.indicatorUnit;
    const keys = Object.keys(indicatorText);
    let obj = {};
    keys.forEach((key, i) => {
      const o = {
        pkId: uuidv4(),
        status: row[key + 'Status'],
        indicator: indicatorText[key],
        monitorValue: this.changeMinusOne(row[key]) + indicatorUnit[key],
      };
      if (i === 0) {
        o['lastUpdateTime'] = this.formateTime(row.lastUpdateTime);
        obj = { ...o };
        obj['children'] = [];
      } else {
        obj.children.push(o);
      }
    });
    result.push(obj);
    return result;
  }
  handlePagination(data) {
    this.pagination = data;
    this.getTableList();
  }
}
</script>
